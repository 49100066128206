<template>
  <div class="list modelos">
    <CRow>
      <CCol sm="12">
        <div class="list__actions">
          <router-link to="/modelos/nuevoModelo"><CButton  color="success"><CIcon  name="cil-plus" /> Agregar nuevo</CButton></router-link>
              <CButton progress :spinner="false" timeout="500" color="info" :onChange="isLoading" v-on:click="reloadData()"><CIcon name="cil-sync" />Actualizar datos</CButton>
        </div>
        <div class="list__table">
          <CDataTable :alignVertical="true"
            :items="modelos"
            :fields="fields"
            :items-per-page="25"
            :columnFilter="true"
            :items-per-page-select="{label: 'Items por página', values: [25, 50, 100, 500, 1000]}"
            :tableFilter="{ external: false, lazy: true, placeholder: 'Filtrar modelos', label: 'Filtro de tabla' }"
            :sorterValue="{ asc: true }"
            :sorter='{ external: false, resetable: true }'
            :loading="isLoading"
            pagination
          >
          <template #marca_id="{item}">
            <td>
              <span>{{parseMarca(item.marca_id)}}</span>
            </td>
          </template>
          <template #createdAt="{item}">
            <td>
              <span>{{new Date(item.createdAt).getDate() + '/' +new Date(item.createdAt).getMonth()+ '/'+new Date(item.createdAt).getFullYear()+ ' ' +  new Date(item.createdAt).getHours() + ':' + new Date(item.createdAt).getMinutes()}}</span>
            </td>
          </template>
          <template #acciones="{item}">
            <td>
              <CButtonGroup >
                <CButton v-on:click="triggerUpdateModelo(item)" v-c-tooltip="'Editar'" color="info"><CIcon name="cil-pencil" /></CButton>
                <CButton v-on:click="triggerDeleteModelo(item)" v-c-tooltip="'Eliminar'" color="danger"><CIcon name="cil-x-circle" /></CButton>
              </CButtonGroup>
            </td>
          </template>
          </CDataTable>
        </div><!-- list__table -->
      </CCol>
    </CRow>
  </div>
</template>

<script>
const modelosService = require('../../services/modelos.service');
const Swal = require('sweetalert2');
const marcasService = require('../../services/marcas.service');

export default {
  name: 'Modelos',
  data() {
    return {
      fields: this.generateFields(),
      modelos: [{}],
      marcas: [{}],
      isLoading: true,
    }
  },
  created() {
    this.getModelos();
    this.getMarcas();
  },
  updated() {

  },
  methods: {
    getModelos: function() {
      modelosService.getModelos(this.$store.state.user.data.id).then((response) => {
        // handle success
        this.modelos = response.data.modelos;
        this.isLoading = false;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },
    getMarcas: function() {
      marcasService.getMarcas(this.$store.state.user.data.id).then((response) => {
        // handle success
        console.log(response);
        this.marcas = response.data.marcas;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },
    reloadData: function() {
      this.isLoading = true;
     this.getModelos();
     this.getMarcas();
    },
    generateFields(){
      const fields = [{
        key: 'name' ,
        label: 'Modelo',
      },{
        key: 'marca_id' ,
        label: 'Marca',
        filter: false,
      },{
        key: 'createdAt' ,
        label: 'Fecha',
        filter: false,
      },{
        key: 'acciones' ,
        label: 'Acciones',
        _style: 'width: 100px;',
        filter: false,
        sorter: false
      }];

      return fields;
    },
    parseMarca(marca_id) {
      let marca_name = '';
      this.marcas.forEach(marca => {
        if(marca.id == marca_id){
          marca_name = marca.name
        }
      });
      return marca_name;
    },
    parseSelect(marca_id = undefined) {
      let select = '<select name="modelo_marca" id="modelo_marca" style="display:block; margin: 20px auto;width:255px;" class="swal2-input">';
      this.marcas.forEach(marca => {
        if(marca.id == marca_id){
          select += `<option value="${marca.id}" selected>${marca.name}</option>`;
        }else{
          select += `<option value="${marca.id}">${marca.name}</option>`;
        }
      });
      select += '</select>'
      return select;
    },
    async triggerUpdateModelo(modelo){
      const { value: formValues } = await Swal.fire({
        title: 'Editar modelo',
        html: `<label style="margin-top: 20px;" for="modelo_name">Nombre del modelo:<input id="modelo_name" name="modelo_name" value="${modelo.name}" class="swal2-input"></label>
              <label style="margin-top: 20px;" for="modelo_marca">Marca del modelo:
               ${this.parseSelect(modelo.marca_id)}</label>`,
        showDenyButton: true,
        preConfirm: () => {
          return {
            name: document.getElementById('modelo_name').value,
            marca_id: document.getElementById('modelo_marca').value
          }
        },
        confirmButtonText: `Aplicar cambios`,
        denyButtonText: `Cancelar`,
      });

      if(!formValues){
        Swal.fire('Operación cancelada', 'No se modificó ningún valor','error');
        return;
      }

      if(formValues.name == modelo.name && formValues.marca_id == modelo.marca_id){
        Swal.fire('No registramos cambios', 'No se modificó ningún valor','warning');
        return;
      }
      
      
      let updatedModelo = {
        name: formValues.name,
        marca_id: formValues.marca_id,
      }
      modelosService.updateModelo(modelo.id, updatedModelo).then(() => {
        Swal.fire('Edición realizada', 'El modelo fue editado correctamente.', 'success');
        this.reloadData();
      }).catch((error) => {
        // handle error
        console.log(error);
        Swal.fire('Hubo un error', 'Consulte al administrador', 'error');
      });
    },
    async triggerDeleteModelo(modelo){
      await Swal.fire({
        title: 'Eliminar modelo',
        html: `Vas a eliminar el modelo: <br>${modelo.name} <br><br><strong>¿Estás seguro?</strong>`,
        showDenyButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          modelosService.deleteModelo(modelo.id).then((result) => {
            Swal.fire('¡Operación realizada!', 'El modelo fue eliminado', 'success');
            this.reloadData();
            console.log(result);
          }).catch((error) => {
            // handle error
            console.log(error);
            Swal.fire('Hubo un error', 'Consulte al administrador', 'error')
          });
        } else if (result.isDenied) {
          Swal.fire('Operación cancelada', '', 'error')
        }
      });
    }
  }
}
</script>
